import { ErrorPage, IErrorPageProps } from '@fanatics-live/common-components';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { MONOLITH_URL } from '@/config/env';

interface ICheckoutError extends IErrorPageProps {
  message?: string;
  statusCode?: number;
}

export const CheckoutError: FC<ICheckoutError> = ({ message, statusCode, ...restProps }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { key: keyLocation } = useLocation();

  const handleClick = useCallback(() => {
    if (keyLocation === 'default') {
      navigate('/invoices', { replace: true });
    } else {
      navigate(-1);
    }
  }, [keyLocation, navigate]);

  return (
    <ErrorPage
      fanaticsCollectBaseUrl={MONOLITH_URL}
      buttonText={t('btn.error_page_button')}
      onButtonClick={handleClick}
      message={message}
      statusCode={statusCode}
      showEvents={false}
      {...restProps}
    />
  );
};
