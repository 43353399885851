import { gql } from '../artifacts/gql';

//   "variables": {
//     "input": {
//       "listing": {
//         "listingId": "f21d66fc-2054-11ee-84f2-0a58a9feac02",
//         "listingType": "FIXED_PRICE",
//         "paymentMethod": [
//           "CARD"
//         ],
//         "shippingType": "VAULT"
//       }
//     }
//   }

export const collectCheckoutPaymentLock = gql(`
  mutation CollectCheckoutPaymentLock($input: CollectCheckoutPaymentLockInput!) {
    collectCheckoutPaymentLock(input: $input) {
      __typename
      successful
      messages {
        __typename
        ...ErrorMessage
      }
      result {
        __typename
        clientSecret
        invoiceId
        paymentIntentId
      }
    }
  }

  fragment ErrorMessage on ValidationMessage {
    __typename
    code
    field
    message
    options {
      __typename
      key
      value
    }
    template
  }
`);
