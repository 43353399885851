import 'focus-visible/dist/focus-visible';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { Global, css } from '@emotion/react';
import { Fonts, fanaticsCollectAppTheme } from '@fanatics-live/common-components';
import { FC, ReactNode } from 'react';

const styles = css`
  .js-focus-visible :focus:not(.focus-visible),
  .js-focus-visible :focus:not(.focus-visible) + [data-focus] {
    outline: none;
    box-shadow: none;
  }
`;

interface IThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: FC<IThemeProviderProps> = (props) => {
  const { children } = props;

  return (
    <ChakraProvider theme={fanaticsCollectAppTheme}>
      <CSSReset />
      <Fonts />
      <Global styles={styles} />
      {children}
    </ChakraProvider>
  );
};
