import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogOverlay,
  ButtonGroup,
  Button,
  Text,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { EColor, EButtonVariant, ETextVariant } from '@fanatics-live/common-components';
import { FC, useRef } from 'react';

import { Nullable } from '@/utils/type';

import { TOptions } from './types';

interface IAlertProps {
  options: Nullable<TOptions>;
  onSubmit: () => void;
  onCancel: () => void;
}

export const Alert: FC<IAlertProps> = (props) => {
  const { options, onSubmit, onCancel } = props;
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog
      isOpen={Boolean(options)}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
      size={'sm'}
      isCentered
      preserveScrollBarGap
      scrollBehavior={'inside'}
    >
      <AlertDialogOverlay>
        <AlertDialogContent maxWidth={'343px'} p={'24px'} borderRadius={0} data-testid={'alert-content'}>
          <AlertDialogCloseButton left={'16px'} color={EColor.Black} />
          <AlertDialogHeader p={0} mb={'16px'} mt={'20px'}>
            <Text variant={ETextVariant.XL} color={EColor.Black} letterSpacing={'-1px'}>
              {options?.title}
            </Text>
          </AlertDialogHeader>

          <AlertDialogBody p={0} mb={'24px'}>
            <Text variant={ETextVariant.BaseParagraph} color={EColor.Black}>
              {options?.content}
            </Text>
          </AlertDialogBody>

          <AlertDialogFooter justifyContent={'center'} p={0}>
            <ButtonGroup spacing={'8px'} w={'100%'}>
              {options?.cancelText && (
                <Button
                  ref={cancelRef}
                  variant={EButtonVariant.BRAND_SECONDARY}
                  w={'100%'}
                  onClick={onCancel}
                  data-testid={'alert-cancel-button'}
                >
                  {options.cancelText}
                </Button>
              )}
              {options?.submitText && (
                <Button
                  variant={EButtonVariant.BRAND_PRIMARY}
                  w={'100%'}
                  onClick={onSubmit}
                  data-testid={'alert-submit-button'}
                >
                  {options.submitText}
                </Button>
              )}
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
