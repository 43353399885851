import { gql } from '../artifacts/gql';

//   "variables": {
//     "input": {
//       "listing": {
//         "listingId": "f21d66fc-2054-11ee-84f2-0a58a9feac02",
//         "listingType": "FIXED_PRICE",
//         "shippingType": "VAULT"
//       }
//     }

export const collectCheckoutDetails = gql(`
  query CollectCheckoutDetails($input: CollectCheckoutDetailsInput!) {
    collectCheckoutDetails(input: $input) {
      __typename
      businessCategory
      invoiceCategory
      invoiceDate
      type
      dueDate
      subTotal {
        __typename
        ...SharedMoney
      }
      shipping {
        __typename
        ...SharedMoney
      }
      tax {
        __typename
        ...SharedMoney
      }
      creditCardFee {
        __typename
        ...SharedMoney
      }
      creditCardFeePct
      potentialCreditCardFee {
        __typename
        ...SharedMoney
      }
      total {
        __typename
        ...SharedMoney
      }
      potentialTotalWithCreditCardFee {
        __typename
        ...SharedMoney
      }
      amountPaid {
        __typename
        ...SharedMoney
      }
      amountPaidOutstanding {
        __typename
        ...SharedMoney
      }
      status
      accountFundsToUse {
        __typename
        ...SharedMoney
      }
      balanceDue {
        __typename
        ...SharedMoney
      }
      potentialBalanceDueWithCreditCardFee {
        __typename
        ...SharedMoney
      }
      shippingType
      lineItems {
        __typename
        title
        listing {
          __typename
          ...ListedProduct
        }
      }
      payments {
        __typename
        paymentMethod
        amount {
          __typename
          ...SharedMoney
        }
      }
      pendingPayments {
        __typename
        paymentMethod
      }
      allowedPaymentMethods
    }
    collectCurrentUserV2 {
      __typename
      firstName
      lastName
      tenantId
      accountFunds {
        __typename
        total {
          __typename
          ...SharedMoney
        }
        pendingUnderwriting {
          __typename
          ...SharedMoney
        }
        usableFunds {
          __typename
          ...SharedMoney
        }
      }
      collectDefaultAddress {
        __typename
        ...CollectSharedAddress
      }
      collectVaultSubmissionAddress {
        __typename
        ...CollectSharedAddress
      }
    }
  }

  fragment CollectSharedAddress on CollectAddress {
    __typename
    name
    addressLine1
    addressLine2
    city
    state
    zip
    country
  }

  fragment ListedProduct on CollectListing {
    __typename
    id
    ...SharedListedProduct
    description
    variant {
      __typename
      ...ListedProductVariant
    }
    ...ListedProductOffer
    collectSales {
      __typename
      soldDate
      soldFor {
        __typename
        ...SharedMoney
      }
    }
    offerLimits {
      __typename
      min {
        __typename
        ...SharedMoney
      }
      max {
        __typename
        ...SharedMoney
      }
    }
  }

  fragment ListedProductOffer on CollectListing {
    __typename
    id
    myOffer {
      __typename
      ...ListingOfferHistory
    }
  }

  fragment ListedProductVariant on CollectVariant {
    __typename
    product {
      __typename
      ...MinimalGeneralProduct
    }
  }

  fragment ListingOfferBid on CollectFixedPriceListingOfferBid {
    __typename
    integerId
    status
    amount {
      __typename
      ...SharedMoney
    }
    insertedAt
    expiresAt
  }

  fragment ListingOfferHistory on CollectFixedPriceListingOfferWithHistory {
    __typename
    id
    listingId
    status
    amount {
      __typename
      ...SharedMoney
    }
    insertedAt
    expiresAt
    sellerViewedAt
    bidderViewedAt
    bids {
      __typename
      ...ListingOfferBid
    }
    listing {
      __typename
      listingStatus: status
    }
    offerRecord: previousOffers {
      __typename
      ...PreviousOfferHistory
    }
    paymentDetails {
      __typename
      invoiceId
      paymentDueDate
    }
  }

  fragment MinimalGeneralProduct on CollectProduct {
    __typename
    id
    images
    title
  }

  fragment PreviousOfferHistory on CollectFixedPriceListingOffer {
    __typename
    id
    listingId
    status
    amount {
      __typename
      ...SharedMoney
    }
    insertedAt
    expiresAt
    bids {
      __typename
      ...ListingOfferBid
    }
    listing {
      __typename
      listingStatus: status
    }
  }

  fragment SharedCollectAuction on CollectAuction {
    __typename
    id
    endsAt
    startsAt
    integerId
    auctionIntegerId
    name
    shortName
    payoutDate
    status
    collectListingTimer {
      __typename
      secondsUntilAuctionStart
      secondsUntilAuctionEnds
      secondsUntilExtednedBiddingEnds
      auctionEndsAt
      auctionStartsAt
      windowEndsAt
      asOf
      windowOrdinal
      windowDurationSeconds
    }
  }

  fragment SharedListedProduct on CollectListing {
    __typename
    id
    title
    integerId
    listingType
    currentBid {
      __typename
      ...SharedMoney
    }
    images
    subtitle
    marketplaceEyeAppeal
    startingPrice {
      __typename
      ...SharedMoney
    }
    listingStatus: status
    lotString
    bidCount
    highestBidder
    buyNowPrice {
      __typename
      ...SharedMoney
    }
    previousAskingPrice {
      __typename
      ...SharedMoney
    }
    isOwner
    allowOffers
    auction {
      __typename
      ...SharedCollectAuction
    }
    favoritedCount
    states {
      __typename
      isClosed
      isGreatPrice
      isWatching
      userBidStatus
      lastWindowWhenBidWasReceived
      userCanBuy
      userMaxBid {
        __typename
        ...SharedMoney
      }
    }
    collectSales {
      __typename
      soldDate
      soldFor {
        __typename
        ...SharedMoney
      }
    }
  }

  fragment SharedMoney on Money {
    __typename
    amountInCents
    currency
  }
`);
