import { MONOLITH_URL } from '@/config/env';

export const getFaviconPath = () => {
  if (process.env.NODE_ENV === 'development') {
    return '/favicon-dev.png';
  }
  if (MONOLITH_URL.includes('dev')) {
    return '/favicon-dev.png';
  }
  if (MONOLITH_URL.includes('qa')) {
    return '/favicon-qa.png';
  }
  if (MONOLITH_URL.includes('uat')) {
    return '/favicon-uat.png';
  }
  return '/favicon.ico';
};
