import { createContext, useContext } from 'react';

interface INavigationContext {
  refetchProfile: () => void;
}

export const NavigationContext = createContext<INavigationContext>({
  refetchProfile: () => null,
});

export const useNavigationContext = () => {
  return useContext(NavigationContext);
};
