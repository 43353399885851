import { useQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import { Outlet } from 'react-router';

import { identify } from '@/buy-now/tracking';
import { collectCurrentUserId } from '@/gql';

export const MainLayout = () => {
  const { data, loading } = useQuery(collectCurrentUserId);
  const [identifyInitialized, setIndentifyInitialized] = useState(false);

  if (!loading && data && !identifyInitialized) {
    const { tenantId, email } = data?.collectCurrentUserV2 ?? {};
    identify(tenantId, { email: email?.address ?? '' });
    setIndentifyInitialized(true);
  }

  return (
    <Box h={'100%'}>
      <Outlet />
    </Box>
  );
};
