import './wdyr';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

import App from './App';
import { GTM_ID } from './config/env';

const container = document.getElementById('root');

// Deprecated: Switching to Segment, remove with rewrite
if (GTM_ID) {
  TagManager.initialize({
    gtmId: GTM_ID,
  });
}

if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}
