import '@/config/i18n';
import {
  ApiClient,
  ApiProvider,
  Auth,
  AuthProvider,
  Loader,
  ModalProvider,
  useFetchProfile,
} from '@fanatics-live/common-components';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import { ReactNode, Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { CheckoutError } from '@/components/CheckoutError';
import {
  API_BASE_URL,
  AUTH_BASE_URL,
  GRAPHQL_API_URL,
  MEMBERS_API_URL,
  MOBILE_BASE_URL,
  MONOLITH_URL,
  FAN_ID_BASE_URL,
  FAN_ID_CLIENT_ID,
  FAN_ID_PREVIEW,
  SPLIT_CLIENT_KEY,
  STRIPE_MARKETPLACE_PUBLISHABLE_KEY,
  BUILD_VERSION,
} from '@/config/env';

import { configureClient } from '../utils/configureClient';

import { AlertProvider } from './alert';
import { DatadogProvider } from './datadog';
import { NavigationProvider } from './navigation';
import { QueryProvider } from './query';
import { ThemeProvider } from './theme';

interface IAppProviderProps {
  children: ReactNode;
}

const auth = new Auth(AUTH_BASE_URL, MOBILE_BASE_URL, 'v2');
export const apiClient = new ApiClient(auth, API_BASE_URL);
configureClient(apiClient);

export const AppProvider = (props: IAppProviderProps) => {
  const { children } = props;

  const navigateToLogin = () => {
    if (process.env.NODE_ENV === 'production') {
      window.location.href = `${MONOLITH_URL}/?l`;
    }
  };

  const { authState, isInitializing, refetchProfile } = useFetchProfile(auth, apiClient, navigateToLogin);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`Build: ${BUILD_VERSION}`);
  }, []);

  const splitConfig = {
    core: {
      authorizationKey: SPLIT_CLIENT_KEY as string,
      key: authState.profile?.userUuid || 'anonymous',
    },
  };

  return (
    <ThemeProvider>
      <SplitFactoryProvider config={splitConfig}>
        <DatadogProvider>
          <AlertProvider>
            <ErrorBoundary FallbackComponent={() => <CheckoutError height={'100vh'} />}>
              <AuthProvider value={authState}>
                <ApiProvider value={apiClient}>
                  <QueryProvider>
                    <ModalProvider
                      membersApiUrl={MEMBERS_API_URL}
                      fanaticsCollectBaseUrl={MONOLITH_URL}
                      checkoutApiUrl={API_BASE_URL}
                      stripeMarketplaceKey={STRIPE_MARKETPLACE_PUBLISHABLE_KEY ?? ''}
                      addPaymentMethodLoginFlow={false} // TODO: use feature flag from split.io
                      graphqlApiUrl={GRAPHQL_API_URL ?? ''}
                      fanIdBaseUrl={FAN_ID_BASE_URL ?? ''}
                      fanIdClientId={FAN_ID_CLIENT_ID ?? ''}
                      fanIdPreview={FAN_ID_PREVIEW}
                    >
                      <NavigationProvider
                        authState={authState}
                        isInitializing={isInitializing}
                        refetchProfile={refetchProfile}
                      >
                        <Suspense fallback={<Loader />}>{isInitializing ? <Loader /> : children}</Suspense>
                      </NavigationProvider>
                    </ModalProvider>
                  </QueryProvider>
                </ApiProvider>
              </AuthProvider>
            </ErrorBoundary>
          </AlertProvider>
        </DatadogProvider>
      </SplitFactoryProvider>
    </ThemeProvider>
  );
};
