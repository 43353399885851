import { gql } from '../artifacts/gql';

//   "variables": {
//     "collectListingId": "f21d66fc-2054-11ee-84f2-0a58a9feac02",
//     "type": "FIXED_PRICE"
//   }

export const collectListing = gql(`
  query CollectListing(
    $collectListingId: UUID!
    $type: CollectListingType!
  ) {
    collectListing(id: $collectListingId, type: $type) {
      __typename
      id
      ...ListingBatchingUpdate
    }
  }

  fragment ListingBatchingUpdate on CollectListing {
    __typename
    id
    states {
      __typename
      isClosed
      isGreatPrice
      isWatching
      userBidStatus
      lastWindowWhenBidWasReceived
      userCanBuy
      userMaxBid {
        __typename
        ...SharedMoney
      }
    }
    currentBid {
      __typename
      ...SharedMoney
    }
    favoritedCount
    collectSales {
      __typename
      soldDate
      soldFor {
        __typename
        ...SharedMoney
      }
    }
    bidCount
  }

  fragment SharedMoney on Money {
    __typename
    amountInCents
    currency
  }
`);
