import { clearFanaticsCollectCredentials } from '@fanatics-live/common-components';
import axios from 'axios';

import { AUTH_BASE_URL, MONOLITH_URL } from '@/config/env';

export const logout = async () => {
  await axios.post(`${AUTH_BASE_URL}/security/api/v1/auth/logout`, null, {
    withCredentials: true,
  });
  clearFanaticsCollectCredentials();

  window.location.href = MONOLITH_URL;
};
