import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { BUILD_VERSION, DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN, ENV } from '@/config/env';

export function initialize(
  sessionSampleRate: number,
  rumSessionSampleRate: number,
  rumSessionReplaySampleRate: number
) {
  if (!DATADOG_APPLICATION_ID || !DATADOG_CLIENT_TOKEN) {
    return;
  }

  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    env: ENV,
    service: 'checkout-ui',
    forwardConsoleLogs: 'all',
    forwardErrorsToLogs: true,
    sessionSampleRate,
  });

  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'checkout-ui',
    env: ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: BUILD_VERSION,
    sessionSampleRate: rumSessionSampleRate,
    sessionReplaySampleRate: rumSessionReplaySampleRate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}
