import { generateMonolithUrl } from '@fanatics-live/common-components';

export const ENV = process.env.REACT_APP_ENV || 'local';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://checkout.api.pwccmarketplace.com';
export const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL || 'https://token.api.pwccmarketplace.com';
export const MEMBERS_API_URL = process.env.REACT_APP_MEMBERS_API_URL || 'https://members.api.pwccmarketplace.com';
export const MOBILE_BASE_URL = process.env.REACT_APP_MOBILE_BASE_URL || 'https://mobile.api.pwccmarketplace.com';
export const MONOLITH_URL = process.env.REACT_APP_MONOLITH_URL || 'https://pwccmarketplace.com';
export const PAST_SEARCH_URL = process.env.REACT_APP_PAST_SEARCH_URL || 'https://saved-search.api.pwccmarketplace.com';
export const SEARCH_URL = process.env.REACT_APP_SEARCH_URL || 'https://universal-search.api.pwccmarketplace.com';

export const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION;
export const DATADOG_APPLICATION_ID = process.env.REACT_APP_DATADOG_APPLICATION_ID;
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
export const FAN_ID_BASE_URL = process.env.REACT_APP_FAN_ID_BASE_URL;
export const FAN_ID_CLIENT_ID = process.env.REACT_APP_FAN_ID_CLIENT_ID;
export const FAN_ID_PREVIEW = process.env.REACT_APP_FAN_ID_PREVIEW;
export const GRAPHQL_API_URL = process.env.REACT_APP_GRAPHQL_API_URL;
export const GTM_ID = process.env.REACT_APP_GTM_ID;
export const SEGMENT_WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY;
export const SPLIT_CLIENT_KEY = process.env.REACT_APP_SPLIT_CLIENT_KEY;
export const STRIPE_CAPITAL_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_CAPITAL_PUBLISHABLE_KEY;
export const STRIPE_MARKETPLACE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_MARKETPLACE_PUBLISHABLE_KEY;
export const STRIPE_VAULT_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_VAULT_PUBLISHABLE_KEY;

export const COOKIE_URL = generateMonolithUrl(MONOLITH_URL, '/cookies');
export const CREATE_SUBMISSIONS_URL = generateMonolithUrl(MONOLITH_URL, '/submissions/create', 'members');
export const EDIT_ACCOUNT_URL = generateMonolithUrl(MONOLITH_URL, '/account-preferences/mailing-address', 'members');
export const INVOICES_URL = generateMonolithUrl(MONOLITH_URL, '/invoices', 'members');
export const PRIVACY_URL = generateMonolithUrl(MONOLITH_URL, '/privacy');
export const RETURNS_URL = generateMonolithUrl(MONOLITH_URL, '/returns');
export const SALES_HISTORY_URL = generateMonolithUrl(MONOLITH_URL, '/', 'sales-history');
export const TERMS_URL = generateMonolithUrl(MONOLITH_URL, '/tos');
export const WEEKLY_AUCTION_URL = generateMonolithUrl(MONOLITH_URL, '/weekly-auction');
