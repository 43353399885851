import { lazyImport } from '@/utils/module';

const { Cart } = lazyImport(() => import('@/buy-now'), 'Cart');
const { Details } = lazyImport(() => import('@/features/payment'), 'Details');
const { Invoices } = lazyImport(() => import('@/features/invoices'), 'Invoices');
const { Payment } = lazyImport(() => import('@/features/payment'), 'Payment');

export const checkoutRoutes = [
  {
    path: 'invoices',
    element: <Invoices />,
  },
  {
    path: 'invoices/:invoiceId',
    element: <Payment />,
  },
  {
    path: 'invoices/:invoiceId/details',
    element: <Details />,
  },
  {
    path: 'cart/:listingId',
    element: <Cart />,
  },
];
