import i18n from 'i18next';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { CheckoutError } from '@/components/CheckoutError';
import { MainLayout } from '@/components/Layout';

import { checkoutRoutes } from './checkout';

const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={'/invoices'} />,
  },
  {
    path: '/',
    element: <MainLayout />,
    children: checkoutRoutes,
  },
  {
    path: '*',
    element: <CheckoutError message={i18n.t('errors.page_not_found')} statusCode={404} />,
  },
];

export const AppRoutes = () => useRoutes(routes);
