import { Box, HStack, Text } from '@chakra-ui/react';
import {
  EColor,
  IAuth,
  Navigation,
  ResponsiveContainer,
  useIsMobile,
  FanaticsCollectLogoFull,
} from '@fanatics-live/common-components';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  MONOLITH_URL,
  PAST_SEARCH_URL,
  FAN_ID_BASE_URL,
  FAN_ID_CLIENT_ID,
  FAN_ID_PREVIEW,
  SEARCH_URL,
} from '@/config/env';
import styles from '@/features/payment/components/styles';
import { logout } from '@/utils/logout';

import { NavigationContext } from './NavigationContext';

interface INavigationProvider {
  children: ReactNode;
  authState: IAuth;
  isInitializing: boolean;
  refetchProfile: () => void;
}

export const NavigationProvider: FC<INavigationProvider> = ({
  children,
  authState,
  isInitializing,
  refetchProfile,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isMobile = useIsMobile();

  const isPaymentPage = pathname.split('/').filter((s) => s !== '').length === 2;

  return (
    <NavigationContext.Provider value={{ refetchProfile }}>
      {isPaymentPage ? (
        <Box minHeight="100vh" background={EColor.White}>
          <ResponsiveContainer centerContent={false} px={{ base: 0, md: '16px' }}>
            <HStack spacing={0} pt={{ base: '24px', md: '28px' }} pb="19px" pl={{ base: '8px', md: 0 }}>
              <FanaticsCollectLogoFull
                w={{ base: '100px', md: '166px' }}
                height={{ base: '24px', md: '40px' }}
                mr={{ base: '3px', md: '8px' }}
                position="absolute"
                top="22px"
              ></FanaticsCollectLogoFull>
              <Box
                flex={1}
                textAlign="center"
                {...(isMobile && {
                  borderBottom: '1px',
                  borderBottomColor: EColor.Neutral15,
                  position: 'absolute',
                  top: '22px',
                  left: 0,
                  right: 0,
                  pb: '8px',
                })}
              >
                <Text {...styles.h1} {...(isMobile && styles.narrowHeading)} ml="10px">
                  {t('common.header_checkout')}
                </Text>
              </Box>
            </HStack>
            {children}
          </ResponsiveContainer>
        </Box>
      ) : (
        <Navigation
          auth={authState}
          fanaticsCollectBaseUrl={MONOLITH_URL}
          fanIdBaseUrl={FAN_ID_BASE_URL ?? ''}
          fanIdClientId={FAN_ID_CLIENT_ID ?? ''}
          fanIdPreview={FAN_ID_PREVIEW}
          searchBaseUrl={SEARCH_URL}
          pastSearchBaseUrl={PAST_SEARCH_URL}
          showNonProdBanner={true}
          isInitializing={isInitializing}
          logoutFn={logout}
        >
          {children}
        </Navigation>
      )}
    </NavigationContext.Provider>
  );
};
