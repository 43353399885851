import { useFaviconLink } from '@fanatics-live/common-components';
import { BrowserRouter } from 'react-router-dom';
import Zendesk from 'react-zendesk';

import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes/app';

import { getFaviconPath } from './utils/getFaviconPath';

const ZENDESK_KEY = '9de4267c-34ae-4222-b591-f3164a1a0498';

const App = () => {
  const faviconPath = getFaviconPath();
  useFaviconLink(faviconPath);
  return (
    <>
      <BrowserRouter>
        <AppProvider>
          <AppRoutes />
        </AppProvider>
      </BrowserRouter>

      <Zendesk defer zendeskKey={ZENDESK_KEY} />
    </>
  );
};

export default App;
