import { gql } from '../artifacts/gql';

export const collectCountries = gql(`
  query CollectCountries {
    collectCountries {
      __typename
      id
      name
      code
    }
  }
`);
