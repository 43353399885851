import { queryClient } from '@fanatics-live/common-components';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FC, lazy, ReactNode, Suspense, useEffect, useState } from 'react';

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then((d) => ({
    default: d.ReactQueryDevtools,
  }))
);

interface IQueryProviderProps {
  children: ReactNode;
}

export const QueryProvider: FC<IQueryProviderProps> = (props) => {
  const { children } = props;
  const [showDevtools, setShowDevtools] = useState<boolean>(false);

  useEffect(() => {
    // @ts-expect-error use `window.toggleDevtools()` to show devtools
    window.toggleDevtools = () => setShowDevtools((prev) => !prev);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
      {showDevtools && (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </Suspense>
      )}
    </QueryClientProvider>
  );
};
