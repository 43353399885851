import { gql } from '../artifacts/gql';

export const collectCurrentUserId = gql(`
  query CollectCurrentUserId {
    collectCurrentUserV2 {
      __typename
      tenantId
      email {
        __typename
        address
      }
    }
  }
`);
