import { createContext, FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';

import { Nullable } from '@/utils/type';

import { Alert } from './Alert';
import { IAlertContext, IController, TOptions } from './types';

export const AlertContext = createContext<IAlertContext>({} as IAlertContext);

interface IAlertProviderProps {
  children: ReactNode;
}

export const AlertProvider: FC<IAlertProviderProps> = (props) => {
  const { children } = props;
  const [options, setOptions] = useState<Nullable<TOptions>>(null);
  const controllerRef = useRef<IController>();

  const handleSubmit = useCallback(() => {
    setOptions(null);
    controllerRef.current?.resolve();
  }, []);

  const handleCancel = useCallback(() => {
    setOptions(null);
    controllerRef.current?.reject();
  }, []);

  const handleShowAlert = useCallback((options: TOptions) => {
    return new Promise<void>((resolve, reject) => {
      setOptions(options);
      controllerRef.current = { resolve, reject };
    });
  }, []);

  const value = useMemo<IAlertContext>(
    () => ({
      showAlert: handleShowAlert,
    }),
    [handleShowAlert]
  );

  return (
    <AlertContext.Provider value={value}>
      {children}
      <Alert options={options} onSubmit={handleSubmit} onCancel={handleCancel} />
    </AlertContext.Provider>
  );
};
